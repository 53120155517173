<template>
    <div class="page airportSubmit">
        <div class="body">
            <div class="image">
                <img :src="productItem.goods_image" :alt="productItem.goods_image"/>
            </div>
            <p class="title">接送机</p>
            <div class="form">
                <div class="desc">请填写预约人信息</div>
                <div class="group">
                    <div class="label">姓名</div>
                    <div class="input">
                        <input type="text" placeholder="预约顾客姓名" v-model="name"/>
                    </div>
                </div>
                <div class="group">
                    <div class="label">电话</div>
                    <div class="input">
                        <input type="number" maxlength="11" placeholder="预约顾客手机号码" v-model="phone"/>
                    </div>
                </div>
                <div class="desc">请填写预约信息</div>
                <div class="group">
                    <div class="label">接送</div>
                    <div class="input">
                        <select v-model="pickup">
                            <option disabled value="">接机/送机</option>
                            <option v-for="item in pickupList" :value="item">{{item}}</option>
                        </select>
                    </div>
                </div>
                <div class="group">
                    <div class="label">时间</div>
                    <div class="input">
                        <select v-model="time">
                            <option disabled value="">请选择接送机乘车时间</option>
                            <option v-for="item in timeList" :value="item">{{item}}</option>
                        </select>
                    </div>
                </div>
                <div class="group">
                    <div class="label">机场</div>
                    <div class="input">
                        <select v-model="airport">
                            <option disabled value="">请选择接送机场</option>
                            <option v-for="item in airportList" :value="item.name">{{item.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="group">
                    <div class="label">航班</div>
                    <div class="input">
                        <input type="text" maxlength="18" placeholder="请填写航班号" v-model="number"/>
                    </div>
                </div>
                <div class="group">
                    <div class="label">人数</div>
                    <div class="input">
                        <input type="text" maxlength="18" placeholder="请选择乘坐人数" v-model="num"/>
                    </div>
                </div>
                <div class="group textarea-d">
                    <div class="label">地址</div>
                    <div class="input">
                        <textarea v-model="pick_upaddres" placeholder="街道楼牌号等详细地址"></textarea>
                    </div>
                </div>
                <div class="note">
                    请确保您填写的接机地址与接送机场处于相同城市，否则会造成预约失败
                </div>
            </div>
        </div>
        <div class="footer">
            <span @click="submitCreateAddress">提交</span>
        </div>
    </div>
</template>

<script>
    import {getAirportList, getUsedate, submitProduct} from "../api/data";
    import _ from "lodash";
    import {Toast} from "vant";

    export default {
        name: "MatterSubmit",
        data: function () {
            return {
                pickupList: ['接机', '送机'],
                airportList: [],
                timeList: [],//所有可选时间
                productItem: null,
                name: null,
                phone: null,
                pickup: "",
                num: null,
                number: null,
                airport: "",
                time: "",
                pick_upaddres: null,
            }
        },
        async beforeMount() {
            let productItem = localStorage.getItem("productItem");
            this.productItem = JSON.parse(productItem)
            console.log('productItem=', this.productItem)
            let res = await getUsedate({});
            if (_.toInteger(res.code) === 1) {
                this.timeList = res.data.data
            } else {
                Toast.fail(res.msg);
            }
            let result = await getAirportList({});
            if (_.toInteger(result.code) === 1) {
                console.log('airportList=', result.data.data)
                this.airportList = result.data.data
            } else {
                Toast.fail(result.msg);
            }
        },
        methods: {
            // 提交信息
            async submitCreateAddress() {
                if (this.name === '' || this.name === null) {
                    Toast.fail('请填写顾客姓名');
                    return false
                } else if (this.phone === '' || this.phone === null) {
                    Toast.fail('请填写顾客手机号码');
                    return false
                } else if (this.pickup === '' || this.pickup === null) {
                    Toast.fail('请选择接送方式');
                    return false
                } else if (this.airport === '' || this.airport === null) {
                    Toast.fail('请选择接送机场');
                    return false
                } else if (this.time === '' || this.time === null) {
                    Toast.fail('请选择出行时间');
                    return false
                } else if (this.number === '' || this.number === null) {
                    Toast.fail('请填写航班号');
                    return false
                } else if (this.num === '' || this.num === '') {
                    Toast.fail('请填写乘坐人数');
                    return false
                } else if (_.toInteger(this.num) > 4) {
                    Toast.fail('您的接机人数超限!单次接送机限最多4人');
                    return false
                } else if (this.pick_upaddres === '' || this.pick_upaddres === '') {
                    Toast.fail('请填写接机详细地址');
                    return false
                }
                let param = {
                    id: this.productItem.id,
                    goods_id: this.productItem.goods_id,
                    name: this.name,
                    phone: this.phone,
                    airport: this.airport,
                    pickup: this.pickup === '接送' ? 1 : 2,
                    time: this.time,
                    number: this.number,
                    num: this.num,
                    pick_upaddres: this.pick_upaddres,
                }
                let res = await submitProduct(param);
                // let res = {code: 1, msg: "请求成功", time: "1625894423", data: {order_on: "2021071013202355991021", order_id: "72"}};
                if (_.toInteger(res.code) === 1) {
                    await this.$router.push({name: 'Orderdetail', query: {orderId: res.data.order_id}});
                } else {
                    Toast.fail(res.msg);
                }

            }
        }
    }
</script>

<style scoped>

    .airportSubmit {
        display: flex;
        flex-direction: column;
        width: 690px;
        flex: 1;
        overflow-x: hidden;
        justify-content: flex-start;
        align-items: center;
        padding: 24px 24px 148px 24px;
        box-sizing: border-box;
        overflow-y: auto;
    }

    .airportSubmit .body {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 20px;
    }


    .airportSubmit .body .image {
        width: 562px;
        height: auto;
        min-height: 582px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .airportSubmit .body .image img {
        width: 100%;
        height: auto;
    }

    .airportSubmit .body .title {
        width: 144px;
        height: 50px;
        font-size: 36px;
        font-weight: bold;
        line-height: 50px;
        color: #212429;
        margin: 59px auto 39px;
    }

    .airportSubmit .body .form {
        width: 642px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background: #FFFFFF;
        border-radius: 16px;
        padding: 30px;
        box-sizing: border-box;
    }

    .airportSubmit .body .form .desc {
        margin-top: 44px;
        margin-bottom: 24px;
        width: 100%;
        height: 42px;
        font-size: 30px;
        font-weight: bold;
        line-height: 42px;
        color: #A05923;
        text-align: center;
    }

    .airportSubmit .body .form .group {
        box-sizing: border-box;
        width: 100%;
        border: 2px solid #222529;
        min-height: 75px;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 20px;
        padding: 23px;
    }

    .airportSubmit .body .form .group .label {
        font-size: 25px;
        color: #212428;
        font-weight: 500;
        letter-spacing: 10px;
    }

    .airportSubmit .body .form .group .input {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 17px;
    }

    .airportSubmit .body .form .group .input input, .airportSubmit .body .form .group .input select, .airportSubmit .form .group .input textarea {
        display: flex;
        flex: 1;
        border: 0;
        background: #FFFFFF;
    }

    .airportSubmit .body .form .group .input textarea {
        padding: 20px 0;
        height: 150px;
    }

    .airportSubmit .body .form .group .input select::-ms-expand {
        display: none;
    }

    .airportSubmit .body .form .group .input input::-webkit-input-placeholder, .airportSubmit .form .group .input input::-moz-placeholder, .airportSubmit .form .group .input input::-ms-input-placeholder, .airportSubmit .form .group .input textarea::-webkit-input-placeholder, .airportSubmit .form .group .input textarea::-moz-placeholder, .airportSubmit .form .group .input textarea::-ms-input-placeholder {
        color: #C7C7C7;
        font-weight: 400;
        font-size: 30px;
    }


    .airportSubmit .body .form .note {
        margin-top: 30px;
        padding: 0 20px;
        font-size: 24px;
        font-weight: 400;
        line-height: 34px;
        color: #FF3313;
    }

    .airportSubmit .form .textarea-d {
        align-items: flex-start;
    }


    .airportSubmit .footer {
        position: fixed;
        bottom: 0;
        background: #FFFFFF;
        width: 690px;
        height: 120px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 40px;
    }

    .airportSubmit .footer span {
        width: 100%;
        height: 80px;
        border: 1px solid #707070;
        background: linear-gradient(125deg, #3A3D43 0%, #202327 100%);
        border-radius: 40px;
        font-size: 30px;
        font-weight: bold;
        color: #E2BE93;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
